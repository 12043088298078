import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const E404Page = ({ data }) => (
  <Layout>
    {console.log(data.e404Json)}
    <SEO title="Página não encontrada" image={data.e404Json.intro.background.childImageSharp.fluid.src} />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile data={data.globalJson.header} />

        <SliderSwiper data={[data.e404Json.introMobile]} center top />

        <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
      </>
    ) : (
      <>
        <Header data={data.globalJson.header} />
       
        <SliderSwiper data={[data.e404Json.intro]} center oneSlideBorder={false} top />

        <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
      </>
    )}
  </Layout>
)

export default E404Page

export const Json = graphql`
  query e404 {
    e404Json {
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
        
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
